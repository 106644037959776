

import React, { useState } from "react";

const Enquirysub = ({ data = [] }) => {
    
    const [currentPage, setCurrentPage] = useState(1); // Current page number
    const [expandedRow, setExpandedRow] = useState(null); // Track which row is expanded


    const recordsPerPage = 10; // Number of records per page
    const totalPages = Math.ceil(data?.length / recordsPerPage); // Total pages

    // Get current page data
    const currentData = data?.slice(
        (currentPage - 1) * recordsPerPage,
        currentPage * recordsPerPage
    );

    const toggleExpand = (index) => {
        setExpandedRow(expandedRow === index ? null : index);
    };


    return (
        <section className="section">
            <div className="row">
                <div className="row col-lg-12 col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Enquiries Data</h5>

                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Phone Num</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Enquiry</th>
                                        <th scope="col" style={{ width: '150px' }}>Date</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {currentData?.map((data1, i) => (
                                        <tr key={data1._id}>
                                            <th scope="row">
                                                {(currentPage - 1) * recordsPerPage + i + 1}
                                            </th>
                                            <td>{data1.name}</td>
                                            <td>{data1.ph}</td>
                                            <td>{data1.email}</td>
                                            <td>
                                                <div
                                                    style={{
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        display:
                                                            expandedRow === i ? "block" : "-webkit-box",
                                                        WebkitLineClamp: expandedRow === i ? "none" : 5,
                                                        WebkitBoxOrient: "vertical",
                                                    }}
                                                >
                                                    {data1.enqText}
                                                </div>
                                                {data1.enqText?.length > 100 && (
                                                    <button
                                                        className="btn btn-link"
                                                        onClick={() => toggleExpand(i)}
                                                    >
                                                        {expandedRow === i
                                                            ? "Show Less"
                                                            : "Read More"}
                                                    </button>
                                                )}
                                            </td>
                                            <td>{data1.addDate.split("T")[0]}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            {/* Pagination Controls */}
                            <div className="d-flex justify-content-between align-items-center mt-3">
                                <button
                                    className="btn btn-secondary"
                                    disabled={currentPage === 1}
                                    onClick={() => setCurrentPage(currentPage - 1)}
                                >
                                    Previous
                                </button>
                                <span>
                                    Page {currentPage} of {totalPages}
                                </span>
                                <button
                                    className="btn btn-secondary"
                                    disabled={currentPage === totalPages}
                                    onClick={() => setCurrentPage(currentPage + 1)}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Enquirysub;