import React, {Component} from 'react';

const Footer =()=>{
return(
  <footer 
  id="footer" 
  className="footer" 
  style={{ 
    backgroundColor: "#343a40", 
    color: "#ffffff", 
    textAlign: "center",
    padding: "27px 0",
    marginTop: "auto"
  }}
>
  <div className="copyright">
   {/*  &copy; {new Date().getFullYear()} <strong>EDUMATE</strong>. All Rights Reserved */}
  </div>
  <div className="credits">
    Designed by 
    <a 
      href="https://EduMate.uniservedata.in" 
      style={{ 
        color: "#ffffff", 
        textDecoration: "none", 
        fontWeight: "bold", 
        marginLeft: "5px"
      }}
    >
     {/*  EDUMATE */} Akhil Bansal
    </a>
  </div>
</footer>



);
}
export default Footer;