import React from "react";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Enquirysub from "./Enquirysub";
import { useNavigate } from "react-router-dom";


const Enquiry = () => {

    const [content, setContent] = useState();

    useEffect(() => {

        moveto();

        const url = 'https://sbvmapi.uniservedata.in/api/enquiry';
        showContent(url);
    }, []);

    const showContent = async (url) => {

        const res = await fetch(url);
        const data = await res.json();   
        
        setContent(data || []);
       
    }

    const navigate = useNavigate();
    // const [authenticated, setauthenticated] = useState(false);
    const moveto = () => {
        const loggedInUser = localStorage.getItem("authenticated");
        if (!loggedInUser) {
            navigate("../Login");
        }
    }

    return (
        <main id="main" class="main">

            <div class="pagetitle">
                <h1>Enquries</h1>
                <nav>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li class="breadcrumb-item">Enquries</li>

                    </ol>
                </nav>
            </div>

            <Enquirysub data={content} />
            <ToastContainer />
        </main>
    );
}

export default Enquiry;