import React from 'react';

const Sidebar =()=>{
    return(
        <aside id="sidebar" className="sidebar">

        <ul className="sidebar-nav" id="sidebar-nav">
    
          <li className="nav-item">
            <a className="nav-link " href="/">
              <i className="bi bi-grid"></i>
              <span>Dashboard</span>
            </a>
          </li>
    
          <li className="nav-item">
            <a className="nav-link " href="../Content">
              <i className="bi bi-grid"></i>
              <span>Content</span>
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link " href="../Slider">
              <i className="bi bi-grid"></i>
              <span>Slider</span>
            </a>
          </li>
         
    
    
          {/* <li className="nav-heading">Pages</li> */}
    
          
          <li className="nav-item">
            <a className="nav-link " href="../PicGallary">
              <i className="bi bi-grid"></i>
              <span>Pic Gallary</span>
            </a>
          </li> 

          <li className="nav-item">
            <a className="nav-link " href="../Enquiry">
              <i className="bi bi-grid"></i>
              <span>Enquiry</span>
            </a>
          </li> 
         
    
          <li className="nav-item">
            <a className="nav-link " href="../Register">
              <i className="bi bi-grid"></i>
              <span>Register</span>
            </a>
          </li> 
    
         {/*  <li className="nav-item">
            <a className="nav-link collapsed" href="Login">
              <i className="bi bi-box-arrow-in-right"></i>
              <span>Login</span>
            </a>
          </li>*/}
    
          
    
        </ul>
    
      </aside>

    );
}
export default Sidebar;