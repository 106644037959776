import React from "react";

import Uploadimg from "./uploadimg";
import { useState, useEffect, useReducer } from "react";
import { Editor } from '@tinymce/tinymce-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from "react-router";

const Addcontent = () => {

    
   // const [content, setContent] = useState();
    const [textboxValue, setTextboxValue] = useState('');
    const [selectValue, setSelectValue] = useState({
        pageName: "Home",
        nameType: "Facelities"
    });
    const [editorContent, setEditorContent] = useState('');

    const [comUpdate, forceUpdate] = useReducer((x) => x + 1, 0);
    const [data, setData] = useState('');

    // Callback function to update data
    const updateData = newData => {
        setData(newData);
        forceUpdate()
    };


    const handleTextboxChange = (event) => {
        setTextboxValue(event.target.value);
    };

    const handleSelectChange = (event) => {
        const { name, value } = event.target

        setSelectValue({
            ...selectValue,
            [name]: value
        });
    };

    const handleEditorChange = (content) => {
        setEditorContent(content);
    };

    const handelSubmit = (e) => {
        e.preventDefault();
        const url = "https://sbvmapi.uniservedata.in/api/content/add";
        SubmitData(url);
    }

    const SubmitData = async (url) => {

        const formData = {
            pageName: selectValue.pageName,
            nameType: selectValue.nameType,
            heading: textboxValue,
            textData: editorContent,
            img: data
        }

        try {

            const res = await fetch(url, {
                method: "post",
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify(formData)
            });

            const res_data = res.status;
            if (res_data == "200") {
                toast.success("Pic Uploded Successfull", {
                    position: "top-right",
                    theme: "colored",
                });
            }
            else {
                toast.error("Pic Uploding Process Failed from Backend", {
                    position: "top-right",
                    theme: "colored",
                });
            }
        }
        catch (error) {
            toast.error("Pic Uploding Process Failed from Frontend"+ error, {
                position: "top-right",
                theme: "colored",
            });
        }  
    }

return (

    <main id="main" class="main">

        <div class="pagetitle">
            <h1>Content Tables</h1>
            <nav>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                    <li class="breadcrumb-item">Content Table</li>

                </ol>
            </nav>
        </div>

        <section class="section">

            <div class="row">
                < div className="mx-auto col-10 col-md-8 col-lg-6  ">

                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">Add Content Form</h5>

                            <form class="row g-3">
                                <div class="col-md-12">
                                    <div class="form-floating mb-3">
                                        <select class="form-select" id="floatingSelect" aria-label="State" name="pageName" value={selectValue.pageName} onChange={handleSelectChange}>
                                            <option selected="" value="Home">Home</option>
                                            <option value="About">About</option>
                                            <option value="Notices">Notices</option>
                                            <option value="About">About</option>
                                            <option value="Information">Information Center</option>

                                        </select>
                                        <label for="floatingSelect">Page Name</label>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-floating mb-3">
                                        <select class="form-select" id="floatingSelect" aria-label="State" name="nameType" value={selectValue.nameType} onChange={handleSelectChange}>
                                            <option selected="" value="Facelities">Facelities</option>
                                            <option value="Mission for Achievement">Mission for Achievement</option>
                                            <option value="Latest News">Latest News</option>
                                            <option value="What We Do">What We Do </option>
                                            <option value="Latest News">Latest News</option>
                                            <option value="Other">Other</option>
                                        </select>
                                        <label for="floatingSelect">Content Type</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating">
                                        <input type="email" class="form-control" id="floatingEmail" name="heading" placeholder="Your Email" onChange={handleTextboxChange} value={textboxValue} />
                                        <label for="floatingEmail">Heading Text</label>
                                    </div>
                                </div>


                                <div class="col-12">
                                    <Editor
                                        initialValue="<p>Enter the Text or Paste it !!!.</p>"
                                        value={editorContent}
                                        onEditorChange={handleEditorChange}

                                    />
                                </div>
                                <div class="col-md-12">
                                    <div class="col-md-12">
                                        <div class="form-floating">
                                            <input class="form-control" type="text" value={data} />
                                            <label for="floatingCity">Image Path</label>
                                        </div>
                                    </div>
                                </div>


                                <div class="text-center">
                                    <button type="submit" class="btn btn-primary" onClick={handelSubmit}>Submit</button>
                                    {/*   <button type="reset" class="btn btn-secondary">Reset</button> */}
                                </div>
                            </form>

                        </div>
                    </div>



                </div>

                <div className="row col-lg-4 col-md-4 " style={{ "margin-left": "50px" }}>
                    <Uploadimg onUpdate={updateData} />
                </div>

            </div>
        </section>
        <ToastContainer />
    </main>
);


}

export default Addcontent;