import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {

  const [user, setuser] = useState({
    name: "",
    password: "",
    email: "",
    repassword: ""
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setuser({
      ...user,
      [name]: value
    })
  }


  const callApi = async (url) => {

    const logData = {
      email: user.email,
      password: user.password,
      name: user.name
    };

    try {
      const data = await fetch(url, {
        method: "post",
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(logData)
      });

      const restatus = await data.json();
     

      if (restatus.success === true) {
        toast.success("User Registration Successfull", {
          position: "top-right",
          theme: "colored",
        });
      }
      else {
        toast.error("User Registration Failed ", {
          position: "top-right",
          theme: "colored",
        });
      }
    }
    catch (e) {
      console.log(e);
    }

  }

  const [res, setRes] = useState(false);


  const handleSubmit = (e) => {
    e.preventDefault()

    if (user.password == user.repassword) {
      const apiData = callApi("https://sbvmapi.uniservedata.in/api/user/register");
    }
    else {
      setRes(false);

      toast.error("Password does not Match", {
        position: "top-right",
        theme: "colored",

      });
    }

  };


  return (
    <main>
      <div className="container">
        {res &&
          <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
            Registration sucessfull
            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
        }

        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                <div className="d-flex justify-content-center py-4">
                  <a href="index.html" className="logo d-flex align-items-center w-auto">
                    <img src="assets/img/logo.png" alt="" />
                    <span className="d-none d-lg-block">Registration</span>
                  </a>
                </div>

                <div className="card mb-3">

                  <div className="card-body">

                    <div className="pt-4 pb-2">
                      <h5 className="card-title text-center pb-0 fs-4">Create an Account</h5>
                      <p className="text-center small">Enter your personal details to create account</p>
                    </div>

                    <form className="row g-3 needs-validation" novalidate>
                      <div className="col-12">
                        <label for="yourName" className="form-label">Your Name</label>
                        <input type="text" name="name" className="form-control" value={user.name} onChange={handleChange} required />
                        <div className="invalid-feedback">Please, enter your name!</div>
                      </div>

                      <div className="col-12">
                        <label for="yourEmail" className="form-label">Your Email</label>
                        <input type="email" name="email" className="form-control" value={user.email} onChange={handleChange} required />
                        <div className="invalid-feedback">Please enter a valid Email adddress!</div>
                      </div>
                      <div className="col-12">
                        <label for="yourPassword" className="form-label">Password</label>
                        <input type="password" name="password" className="form-control" value={user.password} onChange={handleChange} required />
                        <div className="invalid-feedback">Please enter your password!</div>
                      </div>

                      <div className="col-12">
                        <label for="yourPassword" className="form-label">Re Enter Password</label>
                        <input type="password" name="repassword" className="form-control" value={user.repassword} onChange={handleChange} required />
                        <div className="invalid-feedback">Please Re enter your password!</div>
                      </div>

                      {/*    <div className="col-12">
                          <div className="form-check">
                            <input className="form-check-input" name="terms" type="checkbox" value="" id="acceptTerms" required/>
                            <label className="form-check-label" for="acceptTerms">I agree and accept the <a href="#">terms and conditions</a></label>
                            <div className="invalid-feedback">You must agree before submitting.</div>
                          </div>
                        </div> */}
                      <div className="col-12">
                        <button className="btn btn-primary w-100" type="submit" on onClick={handleSubmit}>Create Account</button>
                      </div>
                      <div className="col-12">
                        <p className="small mb-0">Already have an account? <a href="Login">Log in</a></p>
                      </div>
                    </form>

                  </div>
                </div>



              </div>
            </div>
          </div>

        </section>

      </div>
      <ToastContainer />
    </main>
  );
}

export default Register;