import React, { useState, useEffect, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Content = () => {
  const [content, setContent] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [comUpdate, forceUpdate] = useReducer((x) => x + 1, 0);

  const rowsPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    moveto();
    const url = "https://sbvmapi.uniservedata.in/api/content";
    showContent(url);
  }, [comUpdate]);

  const moveto = () => {
    const loggedInUser = localStorage.getItem("authenticated");
    if (!loggedInUser) {
      navigate("/Login");
    }
  };

  const showContent = async (url) => {
    const res = await fetch(url);
    const data = await res.json();
    setContent(data.content_data || []);
  };

  const deleteData = async (id) => {
    const url = `https://sbvmapi.uniservedata.in/api/content/delete/${id}`;
    const res = await fetch(url, { method: "delete" });

    if (res.status === 200) {
      toast.success("Data Deleted Successfully", {
        position: "top-right",
        theme: "colored",
      });
      forceUpdate();
    } else {
      toast.error("Process Failed", {
        position: "top-right",
        theme: "colored",
      });
    }
  };

  const routeChange = () => {
    navigate("/Addcontent");
  };

  const handleReadMore = (id) => {
    setExpandedRow(expandedRow === id ? null : id);
  };

  const totalPages = Math.ceil(content.length / rowsPerPage);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = content.slice(indexOfFirstRow, indexOfLastRow);

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Content Tables</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="index.html">Home</a>
            </li>
            <li className="breadcrumb-item">Content Table</li>
          </ol>
        </nav>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <button
              type="button"
              className="btn btn-success btn-lg"
              onClick={routeChange}
              style={{
                position: "absolute",
                top: "86px",
                right: "50px",
                zIndex: "10000",
               
              }}
            >
              + Add Content
            </button>

            <div
              className="card"
              style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", borderRadius: "10px" }}
            >
              <div className="card-body">
                <h5 className="card-title" style={{ color: "#2C3E50", fontWeight: "bold" }}>
                  Content Data
                </h5>

                <table className="table table-hover">
                  <thead style={{ backgroundColor: "#2980B9", color: "white" }}>
                    <tr>
                      <th>#</th>
                      <th>Page Name</th>
                      <th>Type</th>
                      <th>Heading</th>
                      <th>Text Data</th>
                      <th>Image</th>
                      <th>Date</th>
                      <th>Update</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
  {currentRows.map((content_data, i) => (
    <tr key={content_data._id} style={{ backgroundColor: "#F4F6F7" }}>
      <th scope="row">{indexOfFirstRow + i + 1}</th>
      <td>{content_data.pageName}</td>
      <td>{content_data.nameType}</td>
      <td>{content_data.heading}</td>
      <td>
        {expandedRow === content_data._id
          ? content_data.textData
          : `${content_data.textData.slice(0, 150)}...`}
        <button
          className="btn btn-link"
          onClick={() => handleReadMore(content_data._id)}
          style={{
            display: "block",
            marginTop: "5px",
            textDecoration: "none",
            color: "#007bff",
          }}
        >
          {expandedRow === content_data._id ? "Show Less" : "Read More"}
        </button>
      </td>
      <td>
        <img
          src={content_data.img}
          alt="Content"
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
      </td>
      <td>{content_data.dateCreate}</td>
      <td>
        <Link className="btn btn-primary w-100" to={`../UpdateContent/${content_data._id}`}>
          Update
        </Link>
      </td>
      <td>
        <button
          className="btn btn-danger w-100"
          onClick={() => deleteData(content_data._id)}
        >
          Delete
        </button>
      </td>
    </tr>
  ))}
</tbody>

                </table>

                {/* Pagination Controls */}
                <div className="d-flex justify-content-center mt-3">
                  <nav>
                    <ul className="pagination">
                      {[...Array(totalPages)].map((_, pageIndex) => (
                        <li
                          key={pageIndex}
                          className={`page-item ${pageIndex + 1 === currentPage ? "active" : ""}`}
                        >
                          <button
                            className="page-link"
                            onClick={() => setCurrentPage(pageIndex + 1)}
                          >
                            {pageIndex + 1}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ToastContainer />
    </main>
  );
};

export default Content;
